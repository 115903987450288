import React, { useState } from "react";
import Masonry from "react-masonry-css";
import Modal from "react-modal";
import UseData from "../Hooks/UseData";
import { BsXCircle } from "react-icons/bs";
import { capitalize, colors } from '../helpers';
import Social from './Social';

Modal.setAppElement("#root");

const ServicesList = () => {
  const {
    local,
    servicesArray,
    categoriesArray,
  } = UseData();

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    500: 1,
  };

  // start dynamic portfolio with slug
  const [singleData, setSingleData] = useState(null);

  const handlePortfolioData = (id) => {
    const find = servicesArray.find((item) => item?.id === id);
    setSingleData({ ...find });
  };

  const [test, setTest] = useState("All");

  const handleSearch = (text) => {
    handleData(text);
    setTest(text);
  };

  const [data, setData] = useState(servicesArray);

  const handleData = (text) => {
    if (text === "All") return setData(servicesArray);

    const findData = servicesArray.filter((item) => item.tag === text);
    setData(findData);
  };

  return (
    <>
      <ul className="mt-[40px] flex w-full justify-start md:justify-end flex-wrap font-medium pb-12">
        <li
          className={`${test === "All" ? "text-[#FA5252]" : "fillter-btn"} mr-4 md:mx-4`}
          onClick={() => handleSearch("All")}
        >
          Todos
        </li>
        {categoriesArray.slice(2).map((category) => (
          category && <li
            key={category}
            className={`${test === category ? "text-[#FA5252]" : "fillter-btn"} mr-4 md:mx-4`}
            onClick={() => handleSearch(category)}
          >
            {capitalize(category)}
          </li>
        ))}
      </ul>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data.filter(d => d).map((item) => (
          <div
            className="rounded-lg p-6 dark:border-[2px] border-[#212425]"
            style={{
              background: `${local === "dark" ? "transparent" : colors[item.tag]}`,
            }}
            key={item.id}
            onClick={() => handlePortfolioData(item?.id)}
          >
            <div className="overflow-hidden rounded-lg">
              <img
                src={item.img}
                className="w-full cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 rounded-lg h-auto "
                alt="portfolio"
              />
            </div>
            <span className="pt-5 text-[14px] font-normal text-gray-lite block dark:text-[#A6A6A6]">
              {capitalize(item.tag)}
            </span>
            <h2 className="font-medium cursor-pointer text-xl duration-300 transition hover:text-[#FA5252] dark:hover:text-[#FA5252] dark:text-white mt-2">
              {capitalize(item.title)}
            </h2>
          </div>
        ))}
      </Masonry>

      {singleData && (
        <Modal
          isOpen={singleData ? true : false}
          onRequestClose={() => setSingleData(null)}
          className=" outline-none flex items-center p-4 md:p-8 rounded-2xl my-8"
        >
          <div className=" w-full md:w-10/12 flex items-center  lg:w-[850px] bg-white dark:bg-[#323232] mx-auto rounded-xl p-4 md:p-8 absolute left-1/2 top-1/2 transform -translate-x-[50%] -translate-y-[50%] shadow-lg">
            <div className="overflow-y-scroll max-h-[80vh] no-scrollbar">

              <BsXCircle
                onClick={() => setSingleData(null)}
                className="text-7xl cursor-pointer absolute right-2 -top-12 md:-right-10 md:-top-6 z-50 text-white transition transform hover:rotate-45 duration-300 ease-in-out "
              />

              <h2 className="text-[#ef4060] dark:hover:text-[#FA5252] text-4xl text-center font-bold mb-3">
                {singleData.title}
              </h2>

              <p className="dark:text-white text-2line text-justify font-normal text-[15px] mb-6">
                {singleData.description}
              </p>


              <div style={{ position: "relative" }}>
                <img
                  src={singleData.img}
                  className="w-full md:h-[450px] h-auto object-cover rounded-xl my-[2em]"
                  alt=''
                />
              </div>


              <div className="flex justify-center space-x-3">
                <Social withWhatsapp />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ServicesList;
