export const BASE_URL = 'https://otorrinotijuana.com';
export const PORTFOLIO = 'portfolio-media';
export const WHATSAPP_LINK = 'https://api.whatsapp.com/send?phone=5216634380978&text=Buen%20d%C3%ADa%20Dr.%20Carlos%2C%20me%20gustar%C3%ADa%20agendar%20una%20cita%20por%20favor.';

export const COLORS = ["#FCF4FF", "#FEFAF0", "#FCF4FF", "#FFF4F4", "#FFF0F8", "#F3FAFF"];

export const colors = {
    hernia: COLORS[0],
    cirugías: COLORS[1],
};

export const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

export const isEmptyObject = (obj) => JSON.stringify(obj) === "{}";

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
