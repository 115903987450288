import {
    FaEnvelopeOpenText,
    FaMapMarkerAlt,
    FaMobileAlt,
} from "react-icons/fa";

const Info = () => {
    const personalContent = [
        {
            id: 1,
            border: "border-b border-[#E3E3E3] dark:border-[#3D3A3A]",
            icon: <FaMobileAlt />,
            iconColor: "text-[#E93B81]",
            name: "Teléfono",
            meta: (
                <>
                    <a
                        className="hover:text-[#FA5252] duration-300 transition"
                        href="tel:+6644783000"
                        target='_blank'
                        rel="noreferrer"
                    >
                        +52 (664) 478 3000, Ext.3001
                    </a>
                </>
            ),
        },
        {
            id: 2,
            border: "border-b border-[#E3E3E3] dark:border-[#3D3A3A]",
            icon: <FaMapMarkerAlt />,
            iconColor: "text-[#6AB5B9] ",
            name: "Ubicación",
            meta: <>
                <a
                    className="hover:text-[#FA5252] duration-300 transition"
                    href="https://goo.gl/maps/KoYFH9WtvLLCt7fp8"
                    target='_blank'
                    rel="noreferrer"
                >
                    Antonio Caso 2055, Zona Urbana Rio Tijuana, 22010 Tijuana, B.C., Mexico, <strong>consultorio 313</strong>
                </a>
            </>,
        },
        {
            id: 3,
            // border: "border-b border-[#E3E3E3] dark:border-[#3D3A3A]",
            icon: <FaEnvelopeOpenText />,
            iconColor: "text-[#FD7590]",
            name: "Email",
            meta: (
                <>
                    <a
                        className="hover:text-[#FA5252] duration-300 transition"
                        href="mailto:cvila@drvilasurgery.com"
                        target='_blank'
                        rel="noreferrer"
                    >
                        cvila@drvilasurgery.com
                    </a>
                </>
            ),
        },
    ];

    return (
        <>
            {personalContent.map((item) => (
                <div className={`flex py-2.5 ${item.border}`} key={item.id}>
                    <span
                        className={`flex-shrink-0 socialbtn bg-white dark:bg-black ${item.iconColor} shadow-md`}
                    >
                        {item.icon}
                    </span>
                    <div className="text-left ml-2.5">
                        <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                            {item.name}
                        </p>
                        <p className="dark:text-white break-all">{item.meta}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Info;
