const Footer = () => {
  return (
    <footer className="overflow-hidden rounded-b-2xl bg-slate-50 dark:bg-black">
      <div className="container">
        <p className="text-center py-6 text-gray-lite dark:text-color-910">
          &copy; {new Date().getFullYear()} drvilasurgery.com | Developed by{" "}
          <a
            className="hover:text-[#FA5252] duration-300 transition"
            href="https://merakimx.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Meraki Mx
          </a>
          .
        </p>
      </div>
    </footer>
  );
};

export default Footer;
