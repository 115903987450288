import React from "react";
import Footer from '../components/Footer';
import Seo from '../components/Seo';
import ServicesList from "../components/ServicesList";

const Services = () => {
  return (
    <>
      <Seo title="Servicios" />

      <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container mb-8 px-4 sm:px-5 md:px-10 lg:px-[60px]">
            <div className="py-12">
              <h2 className="after-effect after:left-48 mt-12 lg:mt-0">
                Servicios
              </h2>
              <ServicesList />
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </>
  );
};

export default Services;
