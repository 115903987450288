import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { CgNotes } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { RiContactsBookLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "light");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "light");
      setLocal("light");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }


  // Menu items for Homepage Two
  const menuItemTwo = [
    {
      id: uuidv4(),
      name: "Inicio",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: uuidv4(),
      name: "Experiencia",
      link: "/experience",
      icon: <CgNotes />,
    },
    {
      id: uuidv4(),
      name: "Servicios",
      link: "/services",
      icon: <FiCodesandbox />,
    },
    {
      id: uuidv4(),
      name: "Contacto",
      link: "/contact",
      icon: <RiContactsBookLine />,
    },
  ];

  const serviceCategories = {
    'Cirugía mínima invasión': {
      showInServicesPage: false,
      icon: "/images/icons/laparoscopia.png",
      description: "Cirugía que se realiza por medio de incisiones (cortes) pequeñas y pocos puntos. Durante una cirugía mínimamente invasiva, se hace una o más incisiones pequeñas en el cuerpo y se introduce un laparoscopio a través de una abertura a fin de guiar la cirugía. "
    },
    'Cirugía bariatica': {
      showInServicesPage: false,
      icon: "/images/icons/bariatica.png",
      description: "Contribuye de manera importante a mejorar la calidad de vida. Se ha demostrado que tras una cirugía bariátrica exitosa, los pacientes obtienen beneficios como reducción de la glucemia y la presión arterial, reducción o eliminación de la apnea del sueño, reducción de la carga de trabajo del corazón y de los niveles de colesterol."
    },
    Hernia: {
      showInServicesPage: true,
      icon: "/images/icons/estomago-icon.png",
      description: "Ocurre cuando una porción de tejido, como una parte del intestino, empuja hacia fuera a través de un lugar debilitado en los músculos abdominales.",
      items: [{
        id: uuidv4(),
        tag: "hernia",
        title: "Umbilical",
        img: "/images/services/hernia-umbilical.webp",
        imgSmall: "hernia-umbilical.webp",
        bg: "#F4F4FF",
        description:
          "Es un saco (bolsa) formado del revestimiento interno del vientre (cavidad abdominal) que se abre camino a través de un agujero en la pared abdominal a nivel del ombligo.",
      },
      {
        id: uuidv4(),
        tag: "hernia",
        title: "Ventral (pared)",
        img: "/images/services/3-Types-of-Ventral-Hernias.webp",
        imgSmall: "3-Types-of-Ventral-Hernias.webp",
        bg: "#F4F4FF",
        description:
          "Sacos (bolsas) que se forman cuando el recubrimiento interior de su vientre (abdomen) se sale a través de un orificio en la pared abdominal.",
      },
      {
        id: uuidv4(),
        tag: "hernia",
        title: "Hiatal",
        img: "/images/services/Hernia-hiatal.webp",
        imgSmall: "Hernia-hiatal.webp",
        bg: "#F4F4FF",
        description:
          "Sacos (bolsas) que se forman cuando el recubrimiento interior de su vientre (abdomen) se sale a través de un orificio en la pared abdominal.",
      },
      {
        id: uuidv4(),
        tag: "hernia",
        title: "Inguinal",
        img: "/images/services/hernia-inguinal.webp",
        imgSmall: "hernia-inguinal.webp",
        bg: "#F4F4FF",
        description:
          "Ocurre cuando una porción de tejido, como una parte del intestino, empuja hacia afuera a través de un lugar debilitado en los músculos abdominales.",
      }]
    },
    'Cirugía general': {
      showInServicesPage: true,
      icon: "/images/icons/cirugia-icon.png",
      description: "Procedimiento para extirpar o reparar una parte del cuerpo, o para determinar si hay una enfermedad. La cirugía además es la rama de la medicina que se especializa en el diagnóstico y tratamiento de enfermedades o afecciones mediante operaciones. También se llama operación.",
      items: [{
        id: uuidv4(),
        tag: "cirugías",
        title: "Enfermedad por reflujo gastroesofágico.",
        img: "/images/services/enfermedad-por-reflujo-gastroesofagico.webp",
        imgSmall: "enfermedad-por-reflujo-gastroesofagico.webp",
        bg: "#F4F4FF",
        description:
          "Ocurre cuando un musculo al final del esófago no se cierra adecuadamente. Eso permite que el contenido del estomago regrese, o haga reflujo, hacia el esófago y lo irrite. Produciendo ardor en el pecho o garganta denominada acidez.",
      },
      {
        id: uuidv4(),
        tag: "cirugías",
        title: "Abdomen agudo",
        img: "/images/services/abdomen-agudo.webp",
        imgSmall: "abdomen-agudo.webp",
        bg: "#F4F4FF",
        description:
          "El abdomen agudo es un cuadro clínico de dolor abdominal, que se presenta de forma brusca o aguda, con frecuencia causado por un proceso inflamatorio o perforativo de una víscera hueca intrabdominal. Causa más común: apendicitis.",
      },
      {
        id: uuidv4(),
        tag: "cirugías",
        title: "Exceresis de lesiones piel y tumoraciones",
        img: "/images/services/quiste-sebaceo.webp",
        imgSmall: "quiste-sebaceo.webp",
        bg: "#F4F4FF",
        description:
          "La exéresis se define como una operación quirúrgica necesaria para la remoción de una lesión, un cuerpo extraño del cuerpo del individuo, como podría ser el caso de un órgano o parte de él. Dependiendo de las características de la lesión el tratamiento será diferente, así pueden ser de tipo benigno o incluso maligno.",
      },
      {
        id: uuidv4(),
        tag: "cirugías",
        title: "Biopsias",
        img: "/images/services/biopsias.webp",
        imgSmall: "biopsias.webp",
        bg: "#F4F4FF",
        description:
          "Una biopsia es un procedimiento que se realiza para extraer una muestra de tejido o de células del cuerpo para su análisis en un laboratorio. Es posible que debas someterte a una biopsia si tienes determinados signos y síntomas o si tu proveedor de atención médica identifica algo que sea motivo de preocupación.",
      },
      {
        id: uuidv4(),
        tag: "cirugías",
        title: "Cirugia obesidad",
        img: "/images/services/Cirugía-obesidad.webp",
        imgSmall: "Cirugía-obesidad.webp",
        bg: "#F4F4FF",
        description:
          "El sobrepeso y la obesidad se definen como una acumulación anormal o excesiva de grasa que puede ser perjudicial para la salud. El índice de masa corporal (imc) es un indicador simple de la relación entre el peso y la talla que se utiliza frecuentemente para identificar el sobrepeso y la obesidad en los adultos.",
      },
      {
        id: uuidv4(),
        tag: "cirugías",
        title: "Otros",
        img: "/images/services/otros-procedimientos.webp",
        imgSmall: "otros-procedimientos.webp",
        bg: "#F4F4FF",
        description:
          "Son varios los procedimientos quirúrgicos realizados para tratar padecimientos como:",
        list: [
          {
            title: "Circusición",
            description: "La circuncisión es la extirpación quirúrgica de la piel que cubre la punta del pene."
          },
          {
            title: "Curaciones heridas",
            description: "Una incisión es un corte a través de la piel que se hace durante una cirugía. También se denomina herida quirúrgica. Algunas incisiones son pequeñas, otras son grandes. El tamaño de la incisión depende de la clase de cirugía que se le practicó."
          },
        ]
      }]
    }
  };

  const services = Object.entries(serviceCategories).map(([category, { description, icon }]) => ({ category, description, icon }));
  const servicesArray = Object.values(serviceCategories)
    .map((service) => service.items)
    .flat(1);
  const categoriesArray = Object.keys(serviceCategories);

  const resumeData = [
    {
      id: 1,
      title: "Educación",
      icon: <MdOutlineBusinessCenter />,
      items: [
        {
          id: 1,
          date: "2003",
          title: "Graduado en medicina general. Ced. Prof. 4163984",
          place: "Universidad autónoma de Baja California, México",
          bg: "#FFF4F4",
        },

        {
          id: 2,
          date: "2009",
          title: "Medico especialista en cirugia general. Ced. Prof. 6153986",
          place: "Universidad autonoma de sinaloa.",
          bg: "#FFF1FB",
        },

        {
          id: 3,
          date: "2012",
          title: "Subespecialidad en cirugía laparoscópica.",
          place: "Universidad autónoma de Guadalajara Juan I. Menchaca.",
          bg: "#FFF4F4",
        },
      ],
    },
    {
      id: 2,
      title: "Experiencia",
      icon: <MdOutlineSchool />,
      items: [
        {
          id: 1,
          date: "2018 - presente",
          title: "Miembro activo",
          place: "Colegio mexicano obesidad y enfermedades metabólicas.",
          bg: "#EEF5FA",
        },

        {
          id: 2,
          date: "2016 - presente",
          title: "Miembro activo.",
          place: "Colegio cirujanos generales de Tijuana Baja California.",
          bg: "#F2F4FF",
        },

        {
          id: 3,
          date: "2013",
          title: "Certificado.",
          place: "Consejo mexicano de cirugía general.",
          bg: "#EEF5FA",
        },
      ],
    },
  ];

  const contactInfo = [
    {
      id: uuidv4(),
      icon: "/images/contact/phone-call 1.png",
      title: "Phone ",
      info: (
        <>
          <p>
            <a
              className="text-gray-lite text-lg dark:text-[#A6A6A6] "
              href="tel:+6644783000"
              target='_blank'
              rel="noreferrer"
            >
              +52 (664) 478 3000, Ext.3001
            </a>
          </p>
        </>
      ),
      bg: "#FCF4FF",
    },
    {
      id: uuidv4(),
      icon: "/images/contact/email 1.png",
      title: "Email ",
      info: (
        <>
          <p>
            <a
              className="text-gray-lite text-lg dark:text-[#A6A6A6] email"
              href="mailto:cvila@drvilasurgery.com"
              target='_blank'
              rel="noreferrer"
            >
              cvila@drvilasurgery.com
            </a>
          </p>
        </>
      ),
      bg: "#EEFBFF",
    },
    {
      id: uuidv4(),
      icon: "/images/contact/map 1.png",
      title: "Address ",
      info: (
        <>
          <a
            className="text-gray-lite text-lg dark:text-[#A6A6A6] "
            href="https://goo.gl/maps/KoYFH9WtvLLCt7fp8"
            target='_blank'
            rel="noreferrer"
          >
            Antonio Caso 2055, Zona Urbana Rio Tijuana, 22010 Tijuana, B.C., Mexico
          </a>
        </>
      ),
      bg: "#F2F4FF",
    },
  ];

  return {
    categoriesArray,
    check,
    contactInfo,
    handleTheme,
    local,
    menuItemTwo,
    NavLink,
    services,
    servicesArray,
    resumeData,
  };
};

export default AllData;
