import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './Style.css';
import App from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";

const description = 'Soy el Dr. Carlos Vila medico con especialidad en cirugia general, con formación en cirugía laparoscópica avanzada, y obesidad. Llámanos al 664 478 3000 & 664 478 3003, Ext.: 3001';
const title = 'Dr. Carlos Vila Surgery';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <HelmetProvider>
    <Helmet>
      <link rel='icon' type='image/ico' href='/images/favicon.png' sizes='16x16' />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='www.drvilasurgery.com' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' key='og:image' content='/images/dr-carlos-vila-signing.webp' />
    </Helmet>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>

);
